import {
  Box,
  Flex,
  Button,
  Image,
  Menu,
  MenuButton,
  MenuList,
  MenuGroup,
  MenuItem as DropDownMenuItem,
  Text,
} from "@chakra-ui/core"
import React, { useState, useEffect } from "react"
import { MenuItem } from "./menu-item"
import { ContainerWithoutPadding } from "../containerWithoutPadding"
import { graphql, useStaticQuery } from "gatsby"
import Logo from "../../assets/logo.png"

export const NavigationBar = () => {
  // determined if page has scrolled and if the view is on mobile
  const [scrolled, setScrolled] = useState(false)
  const [show, setShow] = useState(false)
  const handleToggle = () => setShow(!show)

  // change state on scroll
  useEffect(() => {
    const handleScroll = () => {
      const isScrolled = window.scrollY > 100

      if (isScrolled !== scrolled) {
        setScrolled(!scrolled)
      }
    }

    document.addEventListener("scroll", handleScroll, { passive: true })

    return () => {
      document.removeEventListener("scroll", handleScroll)
    }
  }, [scrolled])

  const data = useStaticQuery(graphql`
    query ArchiveRaces {
      allPrismicRaces(sort: {fields: data___title___text, order: DESC}) {
        nodes {
          data {
            title {
              text
            }
            body {
              ... on PrismicRacesBodyImageGallery {
                id
                items {
                  gallery_image {
                    url
                    alt
                    thumbnails
                  }
                }
                primary {
                  name_of_the_gallery {
                    text
                  }
                }
              }
            }
          }
        }
      }
    }
  `)

  const archivedRaces = data.allPrismicRaces.nodes.filter(
    ({ data }) => data.body.length
  )

  return (
    <Box pos="relative" zIndex="999">
      <Box
        bg={["green.500", "green.500", "rgba(255,255,255,0.9)"]}
        pos="fixed"
        w="100%"
      >
        <ContainerWithoutPadding>
          <Flex
            display={["flex", "flex", "none"]}
            onClick={handleToggle}
            p={8}
            h="92px"
            cursor="pointer"
            justifyContent="space-between"
          >
            <Text mt={1} color="white">
              ŐRSÉGI FÉLMARATON
            </Text>
            <svg
              fill="white"
              width="22px"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M0 3h20v2H0V3zm0 6h20v2H0V9zm0 6h20v2H0v-2z" />
            </svg>
          </Flex>
          <Flex
            display={[show ? "block" : "none", show ? "block" : "none", "flex"]}
            flexDirection={["column", "column", "row"]}
            justifyContent="space-between"
          >
            <Flex
              w={["100%", "100%", "40%"]}
              flexDirection={["column", "column", "row"]}
            >
              <MenuItem link="/" text="Kezdőlap" />
              <MenuItem link="/ofm" text="#ŐFM" />
              <MenuItem link="/distances" text="Távok" />
            </Flex>
            <Flex
              display={["none", "none", "flex"]}
              w={["100%", "20%"]}
              justifyContent="center"
            >
              <Image
                src={Logo}
                pos="fixed"
                top="10px"
                transition="width .4s"
                w={scrolled ? "96px" : "132px"}
              />
            </Flex>
            <Flex
              w={["100%", "100%", "40%"]}
              flexDirection={[
                "column-reverse",
                "column-reverse",
                "row-reverse",
              ]}
            >
              <MenuItem link="/contact" text="Kapcsolat" />
              <MenuItem link="/results" text="Eredmények" />
              {archivedRaces.length && (
                <Menu>
                  <MenuButton
                    color={["white", "white", "gray.600"]}
                    px={5}
                    rightIcon="chevron-down"
                    borderTop="4px"
                    borderColor="transparent"
                    color={["white", "white", "gray.600"]}
                    px={5}
                    py={8}
                    display="block"
                    _hover={{ borderColor: "green.500" }}
                  >
                    Galéria
                  </MenuButton>
                  <MenuList
                    bg="rgba(26, 28, 40, 0.9)"
                    borderRadius="none"
                    border="none"
                    color="white"
                  >
                    <DropDownMenuItem
                      as="a"
                      href="/videos"
                      borderLeft="4px"
                      borderColor="transparent"
                      py={4}
                      _focus={{
                        borderColor: "green.500",
                        bg: "red",
                        color: "green.500",
                      }}
                      _hover={{
                        borderColor: "green.500",
                        bg: "red",
                        color: "green.500",
                      }}
                    >
                      Videók
                    </DropDownMenuItem>
                    {archivedRaces.map(({ data }) => (
                      <MenuGroup title={data.title?.text}>
                        {data.body.map(gallery => (
                          <DropDownMenuItem
                            as="a"
                            href={`/gallery/${gallery.id}`}
                            borderLeft="4px"
                            borderColor="transparent"
                            py={4}
                            _focus={{
                              borderColor: "green.500",
                              bg: "red",
                              color: "green.500",
                            }}
                            _hover={{
                              borderColor: "green.500",
                              bg: "red",
                              color: "green.500",
                            }}
                          >
                            {gallery.primary.name_of_the_gallery?.text}
                          </DropDownMenuItem>
                        ))}
                      </MenuGroup>
                    ))}
                  </MenuList>
                </Menu>
              )}
            </Flex>
          </Flex>
        </ContainerWithoutPadding>
      </Box>
      <Box h="90px" />
    </Box>
  )
}
