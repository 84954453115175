import { Box, Text, Button } from "@chakra-ui/core"
import React from "react"
import { Container } from "../container"
import { graphql, useStaticQuery } from "gatsby"
import Slider from "react-animated-slider"
import "./style.css"

export const Slideshow = () => {
  const data = useStaticQuery(graphql`
    query Slideshow {
      allPrismicSlideshows {
        nodes {
          data {
            align
            content {
              text
            }
            cover {
              lg: fluid(maxWidth: 1920) {
                src
              }
              md: fluid(maxWidth: 1200) {
                src
              }
              sm: fluid(maxWidth: 768) {
                src
              }
            }
            link {
              raw
            }
            title {
              text
            }
          }
        }
      }
    }
  `)

  return (
    <Box
      w="100%"
      maxH={["300px", "300px", "650px"]}
      bg="gray.200"
      color="gray.800"
    >
      <Slider infinite={true} autoplay={5000}>
        {data.allPrismicSlideshows.nodes.map(slide => {
          const align = slide.data.align ? slide.data.align : "center"
          return (
            <Box
              maxH={["300px", "300px", "650px"]}
              key={slide.id}
              backgroundImage={[
                `url(${slide.data.cover.sm?.src})`,
                `url(${slide.data.cover.md?.src})`,
                `url(${slide.data.cover.lg?.src})`,
              ]}
              style={{
                backgroundPosition: `${align} center`,
                backgroundSize: "cover",
              }}
            >
              <Container>
                <Box
                  pos="absolute"
                  w={["100%", "100%", "60%"]}
                  right={["0", "0", "100px"]}
                  top={["50px", "50px", "80px"]}
                  bg="rgba(0,0,0,0.6)"
                  p={4}
                  textAlign="right"
                >
                  <Text fontSize="4xl" color="green.500" fontWeight="bold">
                    {slide.data.title?.text}
                  </Text>
                  <Text color="white">{slide.data.content?.text}</Text>
                  <Button
                    as="a"
                    href={slide.data.link?.raw.url}
                    variantColor="green"
                    my={4}
                  >
                    Lefutom
                  </Button>
                </Box>
              </Container>
            </Box>
          )
        })}
      </Slider>
    </Box>
  )
}
