import { Flex } from "@chakra-ui/core"
import React from "react"

type ContainerProps = {
  children: React.ReactNode
}

export const Container = ({ children, ...rest }: ContainerProps) => {
  return (
    <Flex
      flexDirection="column"
      px={4}
      py={6}
      mx="auto"
      maxWidth="1200px"
      minW={["100%", "100%", "760px", "960px", "1100px"]}
      {...rest}
    >
      {children}
    </Flex>
  )
}
