import { Box, Text, Flex, Link } from "@chakra-ui/core"
import React from "react"
import { Container } from "../container"
import { FaFacebookF, FaInstagram, FaYoutube } from "react-icons/fa"


export const SocialMediaIcons = () => (
  <Box display={["none", "none", "block"]} zIndex="1200" position="fixed" w="70px" right="0px" top="100px" color="white">
    <Link
      borderRadius="100%" 
      bg="green.700"
      borderWidth="2px"
      borderColor="green.700"
      h="52px" 
      w="52px"
      textAlign="center"
      lineHeight="52px"
      m={2} 
      display="block"
      _hover={{
        bg: "white",
        color: "green.700"
      }}
      p={2}
      href="https://www.facebook.com/orsegifelmaraton"
      target="_blank"
    >
      <Box as={FaFacebookF} w="100%" h="100%"/>
    </Link>
    <Link 
      borderRadius="100%" 
      bg="green.700"
      borderWidth="2px"
      borderColor="green.700"
      h="52px" 
      w="52px"
      textAlign="center"
      lineHeight="52px"
      m={2} 
      display="block"
      _hover={{
        bg: "white",
        color: "green.700"
      }}
      p={2}
      href="https://www.instagram.com/orsegi_felmaraton/"
      target="_blank"
    >
        <Box as={FaInstagram} w="100%" h="100%"/>
    </Link>
    <Link 
      borderRadius="100%" 
      bg="green.700"
      borderWidth="2px"
      borderColor="green.700"
      h="52px" 
      w="52px"
      textAlign="center"
      lineHeight="52px"
      m={2} 
      display="block"
      _hover={{
        bg: "white",
        color: "green.700"
      }}
      p={2}
      href="https://www.youtube.com/channel/UCL6slQOgrohXZC1xO32-ssw"
      target="_blank"
    >
        <Box as={FaYoutube} w="100%" h="100%"/>
    </Link>
  </Box>
)
