import { Flex } from "@chakra-ui/core"
import React from "react"

type ContainerProps = {
  children: React.ReactNode
}

export const ContainerWithoutPadding = ({ children, ...rest }: ContainerProps) => {
  return (
    <Flex
      flexDirection="column"
      mx="auto"
      maxWidth="1200px"
      minW={["100%", "100%", "768px", "960px", "1100px"]}
      {...rest}
    >
      {children}
    </Flex>
  )
}
