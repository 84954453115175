import { Box, Text, Flex, Link } from "@chakra-ui/core"
import React from "react"
import { Container } from "../container"

export const Footer = () => (
  <Box px={3} w="100%" bg="gray.800" color="white">
    <Container>
      <Flex justifyContent="space-between" minH="120px" flexDirection="column">
        <Flex justifyContent="space-between" flexDirection={["column", "column", "column", "row"]}>
          <Flex flexDirection="column">
            <Text>Kérdésed van, keress bátran:</Text>
            <Link href="mailto:goodfootrunnig@gmail.com">goodfootrunnig@gmail.com</Link>
          </Flex>
          <Box w={["100%", "100%", "100%", "65%"]} my={["8","8","8","0,"]}>
            <Link href="/impress" pr={3}>
              Impresszum
            </Link>
            <Link href="/privacy_policy" px={3}>
              Adatvédelmi Szabályzat
            </Link>
            <Link href="/contest_rules" px={3}>
              Versenyszabályzat
            </Link>
            <Link href="/goodfoot" pl={3}>
              GoodFoot Running Egyesület
            </Link>
          </Box>
        </Flex>
        <Text textAlign="center">
          &copy; {new Date().getFullYear()} GoodFoot
        </Text>
      </Flex>
    </Container>
  </Box>
)
