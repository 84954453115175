import { Box, Text, Image, Grid, Flex, Link, PseudoBox } from "@chakra-ui/core"
import React from "react"
import { Container } from "../container"
import { Title } from "../title"
import { graphql, useStaticQuery } from "gatsby"

export const Sponsors = () => {
  const data = useStaticQuery(graphql`
    query Sponsors {
      allPrismicSponsors(sort: {fields: data___order}) {
        edges {
          node {
            id
            data {
              name {
                text
              }
              link {
                raw
              }
              image {
                fluid(maxWidth: 168) {
                  src
                }
              }
            }
          }
        }
      }
    }
  `)

  if (!data.allPrismicSponsors) return null
  const sponsors = data.allPrismicSponsors.edges

  return (
    <Box px={3} w="100%" color="gray.800">
      <Container>
        <Title text="TÁMOGATÓINK" />

        <Grid
          templateColumns={[
            "repeat(1, 1fr)",
            "repeat(2, 1fr)",
            "repeat(3, 1fr)",
            "repeat(4, 1fr)",
            "repeat(5, 1fr)",
          ]}
          columnGap={3}
          rowGap={3}
        >
          {sponsors.map(({ node }: any) => (
            <Link href={node.data.link.raw.url} target="_blank">
              <PseudoBox
                h="100%"
                p={5}
                borderColor="gray.200"
                borderRadius={4}
                borderWidth="1px"
                _hover={{ boxShadow: "lg" }}
              >
                <Flex
                  flexDirection="column"
                  alignItems="center"
                  justifyContent="center"
                  h="100%"
                >
                  <Image src={node.data.image.fluid?.src} />
                  <Text mt={5} fontSize="lg">
                    {node.data.name?.text}
                  </Text>
                </Flex>
              </PseudoBox>
            </Link>
          ))}
        </Grid>
      </Container>
    </Box>
  )
}
