import { theme as baseTheme } from "@chakra-ui/core"

export const theme = {
  ...baseTheme,
  colors: {
    ...baseTheme.colors,
    green: {
      50: '#f3f6f4',
      100: '#e1eae3',
      200: '#c5d5ca',
      300: '#9eb7a6',
      400: '#73947f',
      500: '#537661',
      600: '#466754',
      700: '#486a54',
      800: '#293c31',
      900: '#233129',
      950: '#131b17',
    },
  },
}
