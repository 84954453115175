import { Box, Link } from "@chakra-ui/core"
import React from "react"

type MenuItemProps = {
  link: string
  text: string
  isActive: boolean
}
export const MenuItem = ({ link, text, isActive }: MenuItemProps) => (
  <Box textAlign="center">
    <Link
      borderTop="4px"
      borderColor={isActive ? "green.700" : "rgba(255,255,255,0)"}
      href={link}
      color={["white", "white", "gray.600"]}
      px={5}
      py={8}
      display="block"
      _hover={{ borderColor: "green.700" }}
    >
      {text}
    </Link>
  </Box>
)
