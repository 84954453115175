import { CSSReset, ThemeProvider } from "@chakra-ui/core"
import React from "react"
import { theme } from "../plugins/chakra-ui/theme"
import { NavigationBar } from "./navigation/navigation-bar"
import { Container } from "./container"
import { Footer } from "./footer"
import { Slideshow } from "./slideshow"
import { Sponsors } from "./sponsors"
import { Newsletter } from "./newsletter"
import { SocialMediaIcons } from "./socialmedia"
import SimpleReactLightbox from "simple-react-lightbox"
import '../utils/style.css'

type LayoutProps = {
  children: React.ReactNode
}

export const Layout = ({ children }: LayoutProps) => {
  return (
    <ThemeProvider theme={theme}>
      <SimpleReactLightbox>
        <CSSReset />
        <NavigationBar />
        <Slideshow />
        <SocialMediaIcons />
        <Container>{children}</Container>
        <Newsletter />
        <Sponsors />
        <Footer />
      </SimpleReactLightbox>
    </ThemeProvider>
  )
}
