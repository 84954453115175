import {Box, PseudoBox, Text } from "@chakra-ui/core"
import React from "react"

export const Title = ({text}) => (
  <Box
    py={6}
    w="100%"
    color="gray.800" 
  >
    <Text
      fontSize="4xl"
      textAlign="center"
      mb={4}
      fontWeight="bold"
      style={{ textTransform: "uppercase" }}
    >{text}</Text>
    <Box mx="auto" w="140px" h="15px" pos="relative" zIndex="90">
      <Box bg="gray.300" w="100%" h="1px" pos="absolute" top="5px"/>
      <Box bg="green.700" w="10px" h="10px" rounded="100%" pos="absolute" left="65px" />
    </Box>
  </Box>
)
