import {
  Box,
  Button,
  Checkbox,
  Input,
  Text,
  Flex,
  useToast,
} from "@chakra-ui/core"
import React, { useState } from "react"
import addToMailchimp from "gatsby-plugin-mailchimp"
import { Container } from "../container"

export const Newsletter = () => {
  const toast = useToast()
  const [email, setEmail] = useState()
  const [isChecked, setIsChecked] = useState(false)

  const handleSubmit = async (e: any) => {
    e.preventDefault()
    if (isChecked) {
      const result = await addToMailchimp(email, {})
      console.log(result)
      toast({
        title: result.msg,
        status: result.result,
        duration: 9000,
        isClosable: true,
      })
    } else {
      toast({
        title:
          "Olvasd el és fogadd el az adatvédelmi irányelveket a feliratkozáshoz!",
        status: "error",
        duration: 9000,
        isClosable: true,
      })
    }
  }
  const onChange = (e: any) => {
    setEmail(e.target.value)
  }
  const checkboxOnChange = (e: any) => {
    setIsChecked(!isChecked)
  }
  return (
    <Box py={8} w="100%" bg="green.700" color="white">
      <Container>
        <Text fontSize="4xl" fontWeight="bold" textAlign="center" mt={4} mb={2}>
          HÍRLEVÉL
        </Text>
        <form onSubmit={handleSubmit}>
          <Flex w="100%" alignItems="center" flexDir="column">
            <Text textAlign="center" mt={2} mb={4} fontSize="lg">
              Íratkozz fel hírlevelünkre, hogy ne maradj le semmiről.
            </Text>
            <Input
              placeholder="email@domain.hu"
              color="gray.700"
              size="lg"
              maxW="320px"
              alignSelf="center"
              mx="auto"
              my={4}
              onChange={onChange}
            />
            <Checkbox
              variantColor="white"
              alignSelf="center"
              mb={4}
              onChange={checkboxOnChange}
            >
              A hírlevélre önként iratkozom fel, a honlap Adatvédelmi
              szabályzatát elolvastam, megismertem.
            </Checkbox>
            <Button
              variantColor="green"
              maxW="250px"
              display="block"
              my={4}
              mx="auto"
              size="lg"
              type="submit"
            >
              Feliratkozom most!
            </Button>
          </Flex>
        </form>
      </Container>
    </Box>
  )
}
